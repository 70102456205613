import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="row">
                <div className="column">
                    <h3>Werx Swap</h3>
                    <div className="links">
                    <ul>
                        <li><Link to="#">Coingecko</Link></li>
                        <li><Link to="#">CoinMarketCap</Link></li>
                        <li><Link to="#">Dexscreener</Link></li>
                    </ul>
                    </div>
                </div>
            
                <div className="column">
                    <h3>Everest</h3>
                    <div className="links">
                    <ul>
                        <li><Link to="#">Coingecko</Link></li>
                        <li><Link to="#">Defi Llama</Link></li>
                        <li><Link to="#">Dexscreener</Link></li>
                        <li><Link to="#">CoinMarketCap</Link></li>
                    </ul>
                    </div>
                </div>
                
                <div className="column">
                    <h3>Swap</h3>
                    <div className="links">
                    <ul>
                        <li><Link to="#">Faucet</Link></li>
                        <li><Link to="#">Metis</Link></li>
                        <li><Link to="#">PolyNetwork</Link></li>
                        <li><Link to="#">BoringDAO</Link></li>
                        <li><Link to="#">Multichain</Link></li>
                    </ul>
                    </div>
                </div>
            
                <div className="column">
                    <h3>Business</h3>
                    <div className="links">
                    <ul>
                        <li><Link to="#">Governance</Link></li>
                        <li><Link to="#">Docs</Link></li>
                    </ul>
                    </div>
                </div> 

                <div className="column">
                </div> 
            </div>
        </footer>
    )
}