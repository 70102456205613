import React, { useState } from 'react'
import './SideBar.css'
import { Link } from 'react-router-dom'
import { ERoute } from '../../constants'

// interface ISideBarProps {}

export const SideBar = () => {

    const [toggleState, setToggleState] = useState(false)

    const toggleFunc = () => {
        setToggleState(!toggleState)
    }

    return (
        <div className='sideBarComponent'>
            <div className="cstm-row responsive-row">
                <div className="mbl-menu">
                    <Link to={ERoute.DASHBOARD}><img src="assets/img/logo.ong" style={{ width: '100%' }} /></Link>
                </div>
                <div className={`toggle-menu ${toggleState ? "toggle-cross" : ''} cursor`} onClick={() => toggleFunc()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="25" viewBox="0 0 34 25" fill="none">
                        <path d="M1.375 1.5H33M1.375 23.5H33M1.375 12.5H33" stroke="#fff" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                        <path d="M1 1.5L25 25.5M1 25.5L25 1.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className={`sidebar ${toggleState ? "width" : ''}`}>
                <Link to={ERoute.DASHBOARD}><img src="assets/img/logo.png" style={{ width: '100%' }} /></Link>
                <ul className="side-menu">
                    <li>
                        <Link to="#" className="swap active">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#7083ac" stroke="none">
                                    <path d="M3120 4508 c-72 -49 -99 -131 -65 -199 9 -19 247 -265 528 -546 l512 -513 -1751 0 -1751 0 -33 -22 c-48 -33 -72 -70 -77 -120 -7 -58 23 -118 74 -149 l38 -24 1955 -3 1955 -2 45 22 c24 13 53 35 63 50 27 36 34 116 14 154 -17 33 -1324 1341 -1359 1361 -38 21 -111 16 -148 -9z"/>
                                    <path d="M665 2176 c-79 -34 -121 -129 -90 -202 11 -28 198 -222 673 -697 361 -362 669 -665 684 -673 15 -8 49 -14 75 -14 90 0 153 63 153 153 0 26 -6 60 -14 75 -8 15 -242 254 -520 532 -278 278 -506 508 -506 512 0 5 787 8 1749 8 l1748 0 33 23 c112 75 87 251 -41 287 -21 6 -741 10 -1974 10 -1581 -1 -1946 -3 -1970 -14z"/>
                                </g>
                            </svg> Swap
                        </Link>
                    </li>
                </ul>

                <div className="social-icon">
                    <Link to={ERoute.DASHBOARD}><i className="lni lni-twitter-filled"></i></Link>
                    <Link to={ERoute.DASHBOARD}><i className="lni lni-discord"></i></Link>
                    <Link to={ERoute.DASHBOARD}><i className="lni lni-telegram-original"></i></Link>
                    <Link to={ERoute.DASHBOARD}><i className="lni lni-medium"></i></Link>
                    <Link to={ERoute.DASHBOARD}><i className="lni lni-github-original"></i></Link>
                </div>
                <p className="copy-right">© 2022 Werx Swap</p>
            </div>
        </div>
    )
}