export const ERoute = {
    DASHBOARD: '/'
}

export const Networks = [
    {
        label: "Rinkeby Network",
        value: "0x4",
        className: ""
    },
    {
        label: "BSC Test Network",
        value: "0x61",
        className: ""
    }
]