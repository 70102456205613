import React from 'react'
import './SwapNew.scss'
import { Link } from 'react-router-dom'
import { CustomSelect } from '../CustomSelect/CustomSelect'
import { InputText } from '../InputText/InputText'
import { Networks } from '../../constants'
import { swapToken } from '../../web3lib/swap'
import { useSelector } from 'react-redux'
//toast
import { toast } from "react-toastify"

export const SwapNew = () => {

    const { walletAddress, networkId } = useSelector((state) => state.wallet)

    const onSwapClick = async () => {
        if (walletAddress) {
            var selectNetwork = document.querySelector("#Network").value
            var toAddress = document.querySelector("#toAddress").value;
            var sendAmount = document.querySelector("#sendAmount").value;
            if (!toAddress || !sendAmount) {
                toast.warning("Input all infos", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            console.log(networkId, selectNetwork, walletAddress, toAddress, sendAmount);
            await swapToken(networkId, selectNetwork, walletAddress, toAddress, sendAmount);
        } else {
            toast.warning("Please connect the Metamask", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className='swapNew'>
            <div className='network__box'>
                <div className='network'>
                    <div className='network__panel'>
                        <label>Select Network</label>
                        <CustomSelect data={Networks} id="Network" />
                    </div>
                </div>
                {/* <div className='network'>
                    <div className='network__panel'>
                        <label>To Network</label>
                        <CustomSelect data={Networks} id="toNetwork" />
                    </div>
                </div> */}
            </div>
            
            <div className='sendAmountBox'>
                <label>Send Amount</label>
                <InputText type="number" placeholder='Input the send amount' className='width-200' id="sendAmount" />
            </div>
            <div className='sendAddress'>
                <label>Send Address</label>
                <InputText type="text" placeholder='Input the send address' className='width-400' id="toAddress" />
            </div>
            <div className='swapBtn'>
                <Link to="#" className="connect-btn cstm-btn" onClick={onSwapClick}><i className="lni lni-power-switch"></i>Swap</Link>
            </div>
        </div>
    )
}