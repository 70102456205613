import React from 'react'
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import { Provider } from 'react-redux'
//redux
import store from './redux/store'
import { ERoute } from './constants'
import './App.css'
import { Dashboard } from './pages/dashboard/Dashboard'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

function App() {

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path={ERoute.DASHBOARD} element={ <Dashboard /> } />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
