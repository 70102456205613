import {
    SET_ADDRESS,
    SET_NETWORKID,
    SET_ERROR,
} from '../action_types';

const INIT_STATE = {
    loading: false,
    networkId: null,
    walletAddress: null,
    errors: null
};

const walletReducer = (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ADDRESS:
            return {
              ...state,
              walletAddress: payload.address
            };
        case SET_NETWORKID:
            return {
                ...state,
                networkId: payload.networkId
            }
        case SET_ERROR:
            return {
                ...state,
                errors: payload.error
            }
        default: return { ...state };
    }
}

export default walletReducer;