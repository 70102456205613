import React from 'react'
import './InputText.css'

// interface IInputTextProps {
//     type?: string
//     value?: string
//     placeholder?: string
//     className?: string
// }

export const InputText = ({ type, value, placeholder, className, id }) => {
    return (
        <input id={id} type={type} value={value} className={`inputText ${className}`} placeholder={placeholder} />
    )
}