/* AUTH */
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_NETWORKID = "SET_NETWORKID";
export const SET_ERROR = "SET_ERROR";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT = "LOGOUT";
export const ADDWALLETTOUSER = "ADDWALLETTOUSER";
export const SETOPPONENTWALLET = "SETOPPONENTWALLET";
