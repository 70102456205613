import React from 'react'
import './CustomSelect.css'

// interface ICustomSelectProps {
//     data: Array<any>
//     id?: string 
// }

export const CustomSelect = ({ data, id }) => {
    return (
        <select className='customSelect' id={id}>        
            {data.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
            ))}
        </select>
    )
}