import React from 'react'
import './Dashboard.scss'
import { Link } from 'react-router-dom'
import { SideBar } from '../../components/SideBar/SideBar'
import { SwapNew } from '../../components/SwapNew/SwapNew'
import { Footer } from '../../components/Footer/Footer'
//toast
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//redux
import { useDispatch, useSelector } from 'react-redux'
import { setAddress, setNetworkId } from '../../redux/wallet/actions'

export const Dashboard = () => {

  const dispatch = useDispatch();
  const address = useSelector((state) => state.wallet.walletAddress);

  const minimum = (address) => {
    const temp = String(address);
    return temp.slice(0, 5) + "..." + temp.slice(38, 42);
  };

  const onConnectClick = async () => {
    console.log("Chain ID: ", window.ethereum.chainId);
  
    if (typeof window.ethereum === "undefined") {
        toast.warning("Please install MetaMask!", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        return;
    }

    if (window.ethereum.chainId !== "0x4" && window.ethereum.chainId !== "0x61") {
        toast.warning("Please choose the Rinkeby testnet or BSC testnet.", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        return;
    }

    if (window.ethereum.selectedAddress !== null) {
        await dispatch(setAddress(window.ethereum.selectedAddress));
        await dispatch(setNetworkId(window.ethereum.chainId));
        console.log("MetaMask was already connected.");
        return;
    }

    if (window.ethereum.selectedAddress === null) {
        try {
            console.log("Connecting wallet...");
            await window.ethereum.request({ method: "eth_requestAccounts" });
            await dispatch(setAddress(window.ethereum.selectedAddress));
            await dispatch(setNetworkId(window.ethereum.chainId));
        } catch (err) {
            console.log("Error: ", err);
        }
    }
  }

    return (
        <div className='dashboardPage'>
          <ToastContainer />
          <section className="main">
            <SideBar />
            <div className='main-content'>

              <div className='content-top-sec'>
                <div className="top-bar">
                  <div className="cstm-row">
                    <div className="main-heading">
                      <h1>Swap Tokens</h1>
                    </div>
                    <div className="top-right-column">
                      {/* <Link to="#" className="icon-btn cstm-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="133" height="84" viewBox="0 0 133 84" fill="none">
                          <path d="M52.3333 2.26672C48.3333 3.33339 41.6666 4.66672 37.6666 5.20006C33.1333 5.86672 44.5999 6.26672 66.9999 6.26672C89.1333 6.26672 100.733 5.86672 96.3333 5.20006C92.3333 4.66672 85.3999 3.33339 80.9999 2.13339C71.1333 -0.399943 61.9333 -0.399943 52.3333 2.26672Z" fill="#FF1616"/>
                          <path d="M33.9333 9.86671C32.7333 10.5334 28.3333 14.1334 24.3333 18C20.3333 21.8667 15.7999 25.3334 14.3333 25.6C12.8666 25.8667 19 25.8667 28.0666 25.6C39.5333 25.2 44.3333 25.4667 44.3333 26.5334C44.3333 27.4667 39.6666 28 29.9333 28H15.3999L12.3333 34.6667L9.26662 41.2L1.79995 41.7334C-2.33339 42 1.79995 42 10.9999 41.7334C20.1999 41.4667 29.6666 41.7334 32.0666 42.5334C36.3333 43.8667 36.7333 43.6 46.3333 34.5334L56.2 25.0667L60.6 28.1334C63 29.8667 68.2 33.8667 72.0666 37.2C77.4 41.6 79.5333 42.6667 80.6 41.6C83.1333 39.0667 90.3333 39.7334 93 42.6667C94.3333 44.1334 97 45.3334 98.8666 45.3334C101.533 45.3334 105.267 48.4 114.733 58L127 70.8V60.2667C127 49.3334 123.933 36.2667 121.267 35.3334C119.4 34.8 100.6 33.4667 93.1333 33.3334C90.2 33.3334 88.3333 32.9334 88.8666 32.4C89.4 31.8667 100.467 31.4667 113.4 31.4667C126.333 31.4667 133.133 31.3334 128.467 31.0667C122.333 30.6667 119.4 29.8667 117.8 28C115.933 26 112.867 25.4667 95.8 24.8C84.8666 24.4 75.6666 23.7334 75.2666 23.3334C74.4666 22.6667 85 22.2667 111 22.2667C120.2 22.2667 124.467 22 120.467 21.7334C114.2 21.2 112.333 20.4 106.6 15.2C103 12 99.6666 9.33338 99.1333 9.33338C76.7333 8.66671 35.5333 8.93338 33.9333 9.86671Z" fill="#FF1616"/>
                          <path d="M48.3333 39.2C43.5333 43.7333 37.9333 49.8667 35.8 52.6667C30.8666 59.2 29.9333 60 25.6666 60C23.2666 60 19.4 62.9333 11.9333 70.4C6.33329 76 1.66663 81.2 1.66663 81.8667C1.66663 82.4 6.46663 78.6667 12.2 73.4667L22.8666 63.8667L28.2 66.5333C34.4666 69.8667 35.9333 70 33.8 67.4667C31.8 65.0667 37.1333 56.4 43.6666 51.4667C46.0666 49.6 49.8 45.3333 51.8 42C53.9333 38.6667 55.9333 36 56.3333 36C57.8 36 60.0666 44 59.4 46.6667C59 48.2667 59.4 49.3333 60.3333 49.3333C61.2666 49.3333 61.6666 50.2667 61.1333 51.3333C60.6 52.8 61.4 53.3333 64.3333 53.3333C69.6666 53.3333 69.4 55.0667 63.4 59.6C57.4 64.1333 51.4 70 53.9333 68.9333C54.8666 68.5333 59.1333 66.6667 63.4 64.9333C67.5333 63.2 74.0666 58.9333 77.6666 55.4667C81.1333 52.1333 84.6 49.3333 85.2666 49.3333C87 49.3333 92.6 61.2 91.5333 62.8C91.1333 63.6 93 63.7333 96.4666 63.2C100.467 62.5333 101.8 62.6667 101.133 63.7333C100.6 64.6667 101.8 67.6 103.667 70.2667C105.533 73.0667 107 76 107 76.9333C107 77.8667 108.333 78.6667 110.067 78.6667C111.667 78.6667 117.133 79.8667 122.2 81.3333C127.267 82.8 131.8 83.7333 132.067 83.4667C132.333 83.0667 125.533 75.6 117 66.8C107.133 56.6667 100.333 50.6667 98.6 50.6667C97.1333 50.6667 93.8 49.0667 91.2666 47.0667C86.7333 43.6 86.6 43.6 82.2 46L77.8 48.4L69.4 41.0667C64.7333 37.2 60.0666 33.2 59 32.4C57.4 31.2 55.4 32.5333 48.3333 39.2Z" fill="#FF1616"/>
                          <path d="M7.8 46.9334C7.4 48.6667 7 54.2667 7 59.4667V68.8L14.3333 61.7334C19.8 56.5334 22.6 54.6667 25.5333 54.6667C28.6 54.6667 30.0667 53.6 32.2 49.8667C33.8 47.3334 35 44.9334 35 44.5334C35 44.2667 29.1333 44 21.8 44C9.26667 44 8.6 44.1334 7.8 46.9334Z" fill="#FF1616"/>
                        </svg> $1.52</Link> */}
                        {address ? (
                          <Link to="#" className="connect-btn cstm-btn"><i className="lni lni-power-switch"></i>{minimum(address)}</Link>
                        ) : (
                          <Link to="#" className="connect-btn cstm-btn" onClick={onConnectClick}><i className="lni lni-power-switch"></i>CONNECT TO WALLET</Link>
                        )}
                      
                    </div>
                  </div>
                </div>

                <div className="chart-sec">
                  <div className="cstm-row">
                    <SwapNew />
                  </div>
                </div>

                <Footer />

              </div>
            </div>
          </section>
        </div>
    )
}