import api from '../../utils/api';
import { toast } from 'react-toastify';
import {
    SET_ADDRESS,
    SET_NETWORKID,
    SET_ERROR
  } from '../action_types';

export const swapToken = async (data) => {
    try {
        const res = await api.post('/swapToken', {data});

        return res;
    } catch (err) {
        console.log(err);
    }
}
  
  export const setAddress = (address) => ({
    type: SET_ADDRESS, 
    payload: { address }
  });
  
  export const setNetworkId = (networkId) => ({
    type: SET_NETWORKID,
    payload: { networkId }
  });
  
  export const setError = (error) => ({
    type: SET_ERROR,
    payload: { error }
  });
  