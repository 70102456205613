// import { web3 } from './web3';

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3 from 'web3';

const ETH_providerUrl = process.env.REACT_APP_ETH_WEB3_PROVIDER;
const Rinkeby_providerUrl = process.env.REACT_APP_ETH_RINKEBY_WEB3_PROVIDER;
const BSC_providerUrl = process.env.REACT_APP_BSC_WEB3_PROVIDER;
const BSCtest_providerUrl = process.env.REACT_APP_BSCTEST_WEB3_PROVIDER;

const web3_ETH = new Web3(window.ethereum || ETH_providerUrl);
const web3_Rinkeby = new Web3(window.ethereum || Rinkeby_providerUrl);
const web3_BSC = new Web3(window.ethereum || BSC_providerUrl);
const web3_BSCTEST = new Web3(window.ethereum || BSCtest_providerUrl);

import ERC20JSONAbi from './abis/ERC20_abi.json';
import BEP20JSONAbi from './abis/BEP20_abi.json';

// Rinkeby network
const ERC20_ContractAddress = "0x8f5f4c39FE566e611281aBf57448D14B7f336B1C";
const IERC20Contract = new web3_Rinkeby.eth.Contract(ERC20JSONAbi, ERC20_ContractAddress);
const ERC20Contract = {
    address: ERC20_ContractAddress,
    abi: ERC20JSONAbi,
    contract: IERC20Contract
};

// BSCtest network
const BEP20_ContractAddress = "0x89996c87d66B1CFBF9bae2E82D51BaEF65624727";
const IBEP20Contract = new web3_BSCTEST.eth.Contract(BEP20JSONAbi, BEP20_ContractAddress);
const BEP20Contract = {
    address: BEP20_ContractAddress,
    abi: BEP20JSONAbi,
    contract: IBEP20Contract
};

export {
    ERC20Contract,
    BEP20Contract
}
