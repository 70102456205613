import { ERC20Contract, BEP20Contract } from './contracts'
import BigNumber from 'bignumber.js';
import axios from 'axios'

export const swapToken = async (currentNetworkId, networkId, fromAddress, toAddress, amount) => {
    switch (networkId) {
        case '0x4':
            console.log("----------------------------------------" ,currentNetworkId, networkId);
            if (currentNetworkId !== networkId) {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: networkId }]
                });
            }
            await ERC20Contract.contract.methods.transfer(toAddress, new BigNumber(amount*10**18)).send({from: fromAddress});
            await axios.post('/api/swap', {to_address: fromAddress, network: 'bsctest', send_amount: amount});
            break;
        case '0x61':
            if (currentNetworkId !== networkId) {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: networkId }]
                });
            }
            await BEP20Contract.contract.methods.transfer(toAddress, new BigNumber(amount*10**18)).send({from: fromAddress});
            await axios.post('/api/swap', {to_address: fromAddress, network: 'rinkeby', send_amount: amount});
            break;
        default:
            break;
    }
}